<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
      <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">
<!--        <div class="background">-->
<!--          &lt;!&ndash;     文字&ndash;&gt;-->
<!--          <div v-sliden-in="{duration:800}" class="titlle1">智能压裂解决方案</div>-->
<!--          <div v-sliden-in="{duration:800}" class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>-->
<!--          <div class="titlle3" @click="dialogVisible=true">-->
<!--            解决方案咨询-->
<!--          </div>-->
<!--        </div>-->
          <subordianteheader :imagepath="'2'" :title1="'智能压裂解决方案'" :title2="'*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。'" @dialogVisible="dialogVisibletrue"></subordianteheader>
        <div class="screenmake0">
          <div v-sliden-in="{duration:800}" class="heighLight">智能压裂服务总览</div>
          <div v-sliden-in="{duration:800}" class="heightLight2">以地质工程一体化为基础，由传统经验型迭代压裂向智能压裂转变，通过云平台+边缘计算提供智能压裂技术服务，充分发掘海量历史数据潜力，提高参数优化时效性及针对性，最大程度控制压裂风险，提升压裂改造效果</div>
          <div v-sliden-in="{duration:800}" class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img loading="lazy" src="@/assets/frack01.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">安东实时迭代服务技术</div>
                </div>
              </div>
            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img loading="lazy" src="@/assets/frack02.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">安东智能压裂系统</div>
                </div>
              </div>
            </div>
          </div>

        </div>
          <div class="screenmake21" style="padding: 0 11.0976314269vw 7.7873918418vh">
            <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 2.2060658579vw;font-weight: unset">业务痛点与挑战</div>
<!--            <div class="line"></div>-->
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">砂堵风险难以预判
                    </div>
                    <div class="title2">
                      压裂施工中难以提前预判砂堵风险，可能导致施工失败、生产中断或井损失。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">EUR产量预测不准确
                    </div>
                    <div class="title2">
                      无法准确预测同一井区的EUR产量，影响投资回报和开发方案的合理性。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">压裂施工参数选择困难
                    </div>
                    <div class="title2">
                      不同井区地质条件复杂，压裂施工参数选择依赖经验或多次试验，增加了时间和资源成本。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="screenmake">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;">1</span>
            <span>&nbsp;&nbsp;压裂参数智能优化</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">基于海量单井历史数据，基于大数据模型建立地质参数、工程参数与EUR映射关系，以最优EUR为核心智能评价工程参数与地层匹配性，实现压裂设计参数智能优化</div>
          <div v-sliden-in="{duration:800}" class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">压裂施工参数优化示意图</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title">不同渗透率条件下簇间距与EUR的关系</div>
                </div>
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">差异化智能分段</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
            <span>&nbsp;&nbsp;压裂实时砂堵风险预警</span>
          </div>
          <div v-sliden-in="{duration:800}" class="Light">建立风险预测模型，实时监测砂堵风险，并对压裂参数进行优化，进而降低砂堵的发生几率</div>
          <div v-sliden-in="{duration:800}" class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">施工压力预测及砂堵风险预警系统</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">安东压裂远程监控及实时分析系统砂堵预警</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
            <span>&nbsp;&nbsp;智能钻井工程</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">基于大模型建立隐性工程特征与EUR映射关系， 明确一组或几组关键隐性工程特征最优方向，基于大模型建立隐性工程特征与工程参数映射关系，根据隐性工程特征与EUR映射关系实时优化施工参数</div>
          <div v-sliden-in="{duration:800}" class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">基于技术团队的实时迭代优化现场</div>
                  <div class="Title2"></div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">压裂效果实时控制</div>
                  <div class="Title2"></div>
                </div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div v-left-in="{duration:800}" class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div v-sliden-in="{duration:800}" class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1"  style="margin-top: 25px">{{item.title2}}</div>
                      <div class="title2">
                        {{item.title3}}

                      </div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
          <bottomTip></bottomTip></div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel" ref="totalModel">
      <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
<!--        <div class="background">-->
<!--          &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->
<!--          <headtip @showDialog="showPartner"></headtip>-->
<!--          &lt;!&ndash;     文字&ndash;&gt;-->
<!--          <div v-zoom-in="{duration:800}" class="titlle1">智能压裂解决方案</div>-->
<!--          <div v-zoom-in="{duration:800}" class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>-->
<!--          <div class="titlle3" @click="dialogVisible=true">-->
<!--            解决方案咨询-->
<!--          </div>-->

<!--        </div>-->
        <sub-header-mobile :imagepath="'/gptModel31.png'" :title1="'智能压裂解决方案'" @clickbtn1="showPartner" @clickbtn2="dialogVisible=true"></sub-header-mobile>
        <div class="screenmake0">
          <div v-sliden-in="{duration:800}" class="heighLight">智能压裂服务总览</div>
          <div v-sliden-in="{duration:800}" class="heightLight2">以地质工程一体化为基础，由传统经验型迭代压裂向智能压裂转变，通过云平台+边缘计算提供智能压裂技术服务，充分发掘海量历史数据潜力，提高参数优化时效性及针对性，最大程度控制压裂风险，提升压裂改造效果</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img loading="lazy" src="@/assets/frack01.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">安东实时迭代服务技术</div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card2">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img loading="lazy" src="@/assets/frack02.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">安东智能压裂系统</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake21" >
          <div v-sliden-in="{duration:800}" class="heighLight">业务痛点与挑战</div>
          <div v-sliden-in="{duration:800}" class="line"></div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">砂堵风险难以预判
                  </div>
                  <div class="title2">
                    压裂施工中难以提前预判砂堵风险，可能导致施工失败、生产中断或井损失。
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">EUR产量预测不准确
                  </div>
                  <div class="title2">
                    无法准确预测同一井区的EUR产量，影响投资回报和开发方案的合理性。
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">压裂施工参数选择困难
                  </div>
                  <div class="title2">
                    不同井区地质条件复杂，压裂施工参数选择依赖经验或多次试验，增加了时间和资源成本。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="screenmake">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">1</span>
            <span>&nbsp;&nbsp;压裂参数智能优化</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">基于海量单井历史数据，基于大数据模型建立地质参数、工程参数与EUR映射关系，以最优EUR为核心智能评价工程参数与地层匹配性，实现压裂设计参数智能优化</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">压裂施工参数优化示意图</div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title">不同渗透率条件下簇间距与EUR的关系</div>
                </div>
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">差异化智能分段</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">2</span>
            <span>&nbsp;&nbsp;压裂实时砂堵风险预警</span>
          </div>
          <div v-sliden-in="{duration:800}" class="Light">建立风险预测模型，实时监测砂堵风险，并对压裂参数进行优化，进而降低砂堵的发生几率</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">施工压力预测及砂堵风险预警系统</div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">安东压裂远程监控及实时分析系统砂堵预警</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">3</span>
            <span>&nbsp;&nbsp;智能钻井工程</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">基于大模型建立隐性工程特征与EUR映射关系， 明确一组或几组关键隐性工程特征最优方向，基于大模型建立隐性工程特征与工程参数映射关系，根据隐性工程特征与EUR映射关系实时优化施工参数</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">基于技术团队的实时迭代优化现场</div>
                  <div class="Title2"></div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">压裂效果实时控制</div>
                  <div class="Title2"></div>
                </div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
          <div v-sliden-in="{duration:800}" class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1">{{item.title2}}</div>
                      <div class="title2">
                        {{item.title3}}

                      </div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vLeftIn} from "@/utils/vLeftIn";
import subordianteheader from "@/views/subordinate/components/subordianteheader.vue";
import SubHeaderMobile from "@/views/subordinate/components/subHeaderMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {SubHeaderMobile, subordianteheader, PartnerTip, resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      pageTitle: '智能压裂解决方案_油气开发作业GPT应用项目服务-油气通GPT',
      pageDescription: '智能压裂解决方案以地质工程一体化为基础，由传统经验型迭代压裂向智能压裂转变，通过云平台+边缘计算提供智能压裂技术服务，充分发掘海量历史数据潜力，提高参数优化时效性及针对性，最大程度控制压裂风险，提升压裂改造效果。',
      pageKeywords: '智能压裂，安东实时迭代服务技术，安东智能压裂系统，压裂参数智能优化，压裂实时砂堵风险预警，智能钻井工程，油气开发作业GPT，油气通GPT',
 scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success3.png"),
        title1:"压裂方案设计GPT",
        title2:"压裂相关性分析",
        title3:"基于地质、工程、产量多元数据，通过聚类、降维、多因素综合评价明确主控因素。",
      },{
        img:require("@/assets/success4.png"),
        title1:"压裂方案设计GPT",
        title2:"EUR预测",
        title3:"基于主控因素权重赋值，以最优EUR为核心实现工程参数智能优化。",
      },
      ]
    }

  },

  methods: {     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight); if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智能压裂解决方案_油气开发作业GPT应用项目服务-油气通GPT',
        pageDescription: '智能压裂解决方案以地质工程一体化为基础，由传统经验型迭代压裂向智能压裂转变，通过云平台+边缘计算提供智能压裂技术服务，充分发掘海量历史数据潜力，提高参数优化时效性及针对性，最大程度控制压裂风险，提升压裂改造效果。',
        pageKeywords: '智能压裂，安东实时迭代服务技术，安东智能压裂系统，压裂参数智能优化，压裂实时砂堵风险预警，智能钻井工程，油气开发作业GPT，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    //width: 100%;
    //height: 100vh;
 .topback{
    position: fixed;
    z-index:999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    //.background{
    //  padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    //  width: 100%;
    //  height: 72.41285537700865vh;
    //  background-image: url("../../assets/menage1.png");
    //  background-position: center;
    //  background-size:cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //  .titlle1 {
    //    margin-top: 15.09270704573548vh;
    //    text-align: center;
    //    font-size: 3.0151935298vw;
    //    font-family: arial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    color: #111111;
    //    line-height: 2.84301606922126vh
    //
    //  }
    //  .title4 {
    //    text-align: center;
    //    margin: 8.82076637824475vh auto 0 auto;
    //    width: 38.45869439630272vw;
    //    font-size: 1.0287117273vw;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    color:#111111;
    //    line-height: 4.31520395550062vh;
    //  }
    //  .titlle3 {
    //    margin: 8.07911001236094vh auto;
    //    cursor: pointer;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //
    //    font-size: 1vw;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    color: #ffffff;
    //    line-height: 3.3374536465vh;
    //    text-align: center;
    //    border-radius: 9.1px;
    //    width: fit-content;
    //    padding: 2.4624227441vh 1.1989601386vw;
    //    height: 4.5624227441vh;
    //    background: #2f6eff;
    //    border: #2f6eff 1px solid;
    //
    //  }
    //  .titlle3:hover{
    //    color: #2168DB;
    //    border: #2168DB 1px solid;
    //    background: rgba(255, 255, 255, 0.5);
    //  }
    //}
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:48vh;
              width: 100%;
              background-image: url("../../assets/frack01.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              //background-color: #2F6EFF;
              .Title1{
                color: black;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }
        .card2{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:48vh;
              border:1px solid #2537a8;
              width: 100%;
              background-image: url("../../assets/frack02.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              //background-color: #2F6EFF;
              .Title1{
                color: black;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }
      }
    }
    .screenmake{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 39.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: center;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height:3.194066749vh;
              }
            }
          }
        }

        .card1{
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          // cursor: pointer;
          opacity: 1;

          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh;
            background: #FFFFFF;
            .imgDesign{
              margin:0 auto;
              width: 80%;
              height:14.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            } .imgDesign1{
                width: 100%;
                height:16.06056860321384vh;
                opacity: 1;
                background-image: url("../../assets/frack31.png");
                background-position: center;
                background-size:cover;
                background-repeat: no-repeat;
                background-color: transparent;
              }
            .titleDesign{
              padding:1.8124845488vh 2.5597920277vw 1.1124845488vh 2.5597920277vw;
              .Title1{
                margin-top: 0.4vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title{
                text-align: center;
                margin: 1vh 0 1vh 0;
                height: 4.6971569839vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.194066749vh;
              }
              .Title2{
                text-align: center;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48.19653893695921vh ;
            background: white;
            .imgDesign{
              width: 100%;
              height: 40vh;
              opacity: 1;
              background-image: url("../../assets/frack4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48.19653893695921vh ;
            background: white;
            .imgDesign{
              width: 100%;
              height: 40vh;
              opacity: 1;
              background-image: url("../../assets/frack5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height:39.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                position: absolute;
                top: 42vh;
                left: 45%;
                transform: translateX(-38%);
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin: 8.5vh 2.5597920277vw auto 2.5597920277vw;
                text-align: center;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height:39.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                position: absolute;
                top: 42vh;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin: 8.5vh 2.5597920277vw auto 2.5597920277vw;
                text-align: center;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }


      }
    }

    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top: 25px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 3vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/gptModel31.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.2373300371vh;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-35px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      padding:15px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:50px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:215px;
              width: 100%;
              background-image: url("../../assets/frack01.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card2{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:215px;
              width: 100%;
              background-image: url("../../assets/frack02.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/frack2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          height:fit-content ;
          padding:10px;
          // cursor: pointer;
          opacity: 1;

          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              margin:0 auto;
              width: 100%;
              height:95px;
              opacity: 1;
              background-image: url("../../assets/frack3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            } .imgDesign1{
                width: 100%;
                height:78px;
                opacity: 1;
                background-image: url("../../assets/frack31.png");
                background-position: center;
                background-size:cover;
                background-repeat: no-repeat;
                background-color: transparent;
              }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                text-align: center;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
       }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:200px;
              width: 100%;
              background-image: url("../../assets/frack4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:180px;
              width: 100%;
              background-image: url("../../assets/frack5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }

    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/frack6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:215px;
              width: 100%;
              background-image: url("../../assets/frack7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 15px;
      background: #212a3c;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;

        .card{
          // cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 360px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:254px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 430px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -88px -10px  ;
          // cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
