

<template>
  <div class="background" :style="{height:backHeight + 'px',backgroundImage: 'url(' + require('@/assets'+imagepath) + ')'}">
    <!--      logo  you气通GPT社区 合作伙伴  登录-->
    <headtip @showDialog="dialogVisible"></headtip>
    <!--     文字-->
    <div v-zoom-in="{duration:800}" class="titlle1" v-html="title1"></div>
    <div v-zoom-in="{duration:800}" class="title2"  v-html="title2"></div>
    <div class="titlle3" @click="clickbtn2">
      产品咨询
    </div>
  </div>
</template>
<script>

import headtip from "@/views/headTip.vue";
import {vZoomIn} from "@/utils/vZoomIn";
export default {
  directives:{
    zoomIn:vZoomIn
  },
  props: {
    imagepath:{
      type:String,
      default:'/gptModel11.png'
    },
    backHeight: {
      type: Number,
      default: 400
    },
    title1:{
      type:String,
      default: '智能油藏地质解决方案'
    },
    title2:{
      type:String,
      default:'*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。'
    }
},
  components:{headtip},
  methods:{
    dialogVisible(){
      this.$emit("clickbtn1",true)
    },
    clickbtn2(){
      this.$emit('clickbtn2',true)
    }
  }

}
</script>
<style scoped lang="scss">
.background{
  //z-index: 3;
  position: relative;
  width: 100%;
  //height: 460px;
  padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
  //background-image: url("../../../assets/gptModel11.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  /* Add any additional styling as needed */
  .titlle1 {
    margin:35px auto 0 auto;
    width: fit-content;
    text-align: center;
    height: fit-content;
    //font-size: 23px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //font-weight: 500;
    //color: #111111;
    //line-height: 8.2373300371vh;
    font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336
  }
  .title2 {
    margin: 30px auto;
    width: 90%;
    height: fit-content;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: bolder;
    font-size: 18px;
    line-height: 28px;
    color:#2c3e50;
    text-align: left
  }
  .titlle3{
    position: absolute;
    bottom:50px;
    left:50%;
    transform: translateX(-60%);
    font-size: 14px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9.1px;
    width: fit-content;
    margin: 0 15px;
    padding: 5px 4.6875vw;
    height: 35px;
    color:white;
    background-color: #2f6eff;
    border: 1px solid #2f6eff;
    overflow: hidden;
  }
}
</style>
