<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">ABOUT US</div>
              <div class="title1">关于我们</div>
              <!--              <div class="title2">安东油气田开发研究院</div>-->

            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight"> 公司简介</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  src="@/assets/introduce1.png">
                  </div>
                  <div class="titleDesign">
                    <div>
                      <div class="title2" style="    margin: 12vh auto 0 auto;">
                        油气通是由北京石由同学信息咨询有限公司倾力打造并运营，秉承服务于油气行业各方，让油田数智化简单易行。<br>
                        北京石油同学信息咨询有限公司作为一家以技术创新为核心的油气领域人工智能技术公司，专注为石油行业提供人工智能技术解决方案，致力于将人工智能技术深度融入石油业务中。通过人工智能技术，引领新一代油田数智化发展，面向油田现场全场景，与数据、软件、工控系统、物联网等技术融合，形成整体解决方案，并扎根现场，为客户提供伴随式服务。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div>
                      <div class="title2" style="    margin: 12vh auto 0 auto;">
                        &nbsp;&nbsp;&nbsp;&nbsp;我们拥有一支人工智能专业团队。涵盖产品设计、算法工程、数据治理标注、知识管理、开发实施以及产品运维等多个关键领域。同时人工智能算法库也涵盖了当前最热门的GPT大模型，以及机器学习、深度学习等多种算法模型，这些算法在知识问答、工程设计、风险预警、辅助决策等多个场景都展现出了卓越的性能。能够通过数智化技术与油气技术完美结合，立足于油气田现场伴随式服务，以最简单方式、最低成本、最快周期、最易落地为特点，提供油气行业的全栈式解决方案。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;同时依托安东集团在油气领域的深厚技术积累和丰富经验，为客户提供涵盖油藏地质、钻井、压裂、采油气等全场景的智能解决方案。致力于通过人工智能技术优化油气开发的各个环节，提高开发效率，降低运营成本，并助力客户实现数字化转型和智能化升级。
                      </div>
                    </div>
                  </div>
                  <div class="imgDesign">
                    <img loading="lazy"  src="@/assets/introduce2.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],
  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      changeVisable:1,
      title:'',
      token: localStorage.getItem("Zyaccess_token"),
      configmobile: {
        headerBGC:'rgba(16,31,115,0.5)',
        oddRowBGC:'rgba(75,173,197,0.1)',
        evenRowBGC:'rgba(48,92,194,0.1)',
        rowNum:6,
        header: ['职位名称', '所属部门', '工作地点'],
        align:['center','center','center'],
        data: [],
        waitTime:2000,
      },
      config: {
        headerBGC:'rgba(16,31,115,0.5)',
        oddRowBGC:'rgba(75,173,197,0.1)',
        evenRowBGC:'rgba(48,92,194,0.1)',
        rowNum:8,
        header: ['职位名称', '所属部门', '工作地点'],
        align:['center','center','center'],
        data: [],
        waitTime:2000,
      },
      pageTitle: '关于我们-地质工程云平台GPT',
      pageDescription: '安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。 业务包括油藏地质研究技术服务、地质工程技术服务、增产技术服务、油藏数字化服务以及整体解决方案增产服务。',
      pageKeywords: '地质工程云平台GPT,关于我们',
      model:false,
      dialogconsult:false,
      dialogVisible:false,

    }

  },


  methods: {
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipto(config){
      console.log('config', config.row)
      window.open("https://wecruit.hotjob.cn/SU64db9dff6202cc36e28c3a96/pb/index.html#/")
    },
    getTalent(){
      this.$axios
          .get("/info/job/list",{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.job = res.data.rows
            console.log("--------ren")
            const newData = this.job.map(item => [item.name, item.dept, item.address]);
            // 清除原有数据
            this.config.data = [];
            // const dataItem = ['压裂设计工程师', '油气田开发研究院', '成都/郑州'];
            for(var i=0;i<newData.length;i++){
              this.config.data.push(newData[i]);
            }

            this.config = { ...this.config }
            this.configmobile.data = [];
            // const dataItem = ['压裂设计工程师', '油气田开发研究院', '成都/郑州'];
            for(var j=0;j<newData.length;j++){
              this.configmobile.data.push(newData[j]);
            }

            this.configmobile = { ...this.configmobile }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    const videoEl = this.$refs.myVideo;
    videoEl.play();
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  created() {
    this.getTalent()
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '关于我们-地质工程云平台GPT',
        pageDescription: '安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。 业务包括油藏地质研究技术服务、地质工程技术服务、增产技术服务、油藏数字化服务以及整体解决方案增产服务。',
        pageKeywords: '地质工程云平台GPT,关于我们',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 70vh;

      padding: 2.84301606922126vh 1.5vw  2.84301606922126vh 5vw;
      background-image: url("../assets/groundabout.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 7vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 8vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9.1px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        color: #3a75fe;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width:60%;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 0 4.5248454883vw 2.5248454883vh 2%;
              margin:0 1vw auto auto;
              color: #3a75fe;
              width: 45%;
              float: right;
              position: relative;
              .title1{
                position: absolute;
                margin: 5vh auto;
                width: 82%;
                height: fit-content;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{

                text-align: justify;
                width: 100%;
                margin: 3vh auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                margin: 10.5vh auto;
                width: 70%;
                display: grid;
                grid-template-columns: minmax(0,1fr);
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title4{
                position: absolute;
                margin: 5vh auto;
                left:50%;
                transform: translateX(-20%);
                width: 82%;
                z-index: 99999;
                color: white;
                color: white;
                height: fit-content;
                font-size:1.5vw;
                font-weight: bold;
              }

            }

          }
        }


      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        color: #3a75fe;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width:60%;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 0 4.5248454883vw 2.5248454883vh 2%;
              margin:0 1vw auto auto;
              color: #3a75fe;
              width: 45%;
              float: right;
              position: relative;
              .title1{
                position: absolute;
                margin: 5vh auto;
                width: 82%;
                height: fit-content;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{

                text-align: justify;
                width: 100%;
                margin: 3vh auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                margin: 10.5vh auto;
                width: 70%;
                display: grid;
                grid-template-columns: minmax(0,1fr);
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title4{
                position: absolute;
                margin: 5vh auto;
                left:50%;
                transform: translateX(-20%);
                width: 82%;
                z-index: 99999;
                color: white;
                color: white;
                height: fit-content;
                font-size:1.5vw;
                font-weight: bold;
              }

            }

          }
        }


      }
    }
  }

}
</style>
