<template>
  <div class="carousel-container">
    <div class="carousel-wrapper" :style="carouselStyle">
      <div
          v-for="(person, index) in people"
          :key="index"
          class="person-card"
      >
        <img :src="person.image" :alt="person.name" class="person-image" />
        <div class="person-info">
          <h3>{{ person.name }}</h3>
          <p>{{ person.role }}</p>
        </div>
      </div>
    </div>

    <button class="prev" @click="prevSlide">←</button>
    <button class="next" @click="nextSlide">→</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      people: [
        { name: "张三", role: "前端开发", image: "https://via.placeholder.com/150" },
        { name: "李四", role: "后端开发", image: "https://via.placeholder.com/150" },
        { name: "王五", role: "产品经理", image: "https://via.placeholder.com/150" },
        { name: "赵六", role: "UI设计师", image: "https://via.placeholder.com/150" },
      ],
      slideWidth: 200, // 每个卡片的宽度
      autoSlideInterval: null,
    };
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentIndex * this.slideWidth}px)`,
        transition: "transform 0.5s ease-in-out",
      };
    },
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.people.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.people.length) % this.people.length;
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.nextSlide();
      }, 3000); // 每3秒切换一次
    },
    stopAutoSlide() {
      if (this.autoSlideInterval) {
        clearInterval(this.autoSlideInterval);
        this.autoSlideInterval = null;
      }
    },
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeDestroy() {
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  width: 50vw;
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
}

.person-card {
  width: 40vw;
  margin-right: 20px;
  text-align: center;
}

.person-image {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.person-info h3 {
  margin: 0;
  font-size: 16px;
}

.person-info p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

button.prev {
  left: 0;
}

button.next {
  right: 0;
}
</style>
