<template>

  <div>
    <div v-if="model===false">

    </div>
    <div v-else>
      <div class="totalModel"  ref="totalModel">
        <div @click="changeheadVisable">   <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">生产工艺优化GPTs</div>
            <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">借助AI模型优化生产工艺，提高开发效率，降低风险损失，保障油气田开发安全稳定</div>
          </div>

          <div class="titlle3" @click="clickConsult('油气通')" style="font-weight:bolder;width:140px">
            产品咨询
          </div>
          <div v-zoom-in="{duration:800}" class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。   <br>①&nbsp;工艺类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">智能钻井模型架构</div>
          <div class="heightLight2">以精细三维地质建模为基础，运用数字岩屑、钻时、气测等综合录井资料和地震提频、储层反演成果进行综合地质导向，开展实时调整，提高储层钻遇率。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gptModel22.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">   <span>生产工艺优化GPTs服务</span></div>
          <div class="grid">
            <div v-sliden-in="{duration:800}"  class="card">
<!--              <div class="back">-->
              <div class="back" @click="detail">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1" style="font-weight: bolder">智能分析地质分层</div>
                  <div class="Title2">
                    <li >模型可综合运用MWD和LWD数据，实时分析地质分层信息</li>
                    <li >根据预设阈值自动生成预警信息，辅助工程人员及时调整钻井参数</li>
                    <li >历史数据和实时数据的对比分析,辅助地质分层预测和钻径参数优化</li>
                  </div>
                  <div class="backconsult" @click="detail" style="font-weight: bolder;color: #ed9629">
                    免费体验
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card">
              <div class="back" @click="detail1">
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">智能预测预计采收储量</div>
                  <div class="Title2">
                    <li>智能预测采收储量（EUR）</li>
                    <li>对影响压裂的工程参数和地质参数进行相关性分析</li>
<!--                    通过先进的数据分析技术，结合压裂参数，为您分析最终可采储量。不仅如此，系统还能根据EUR值反向推算关键参数，实现历史记录的深度挖掘和相关性分析，帮助您优化油藏管理和提高采收效率。-->
                  </div>
                  <div class="backconsult" @click="detail1" style="font-weight: bolder;color: #ed9629">
                    免费体验
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back" @click="detail4">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1" style="margin-top: 175px">钻井施工参数生成</div>
                  <div class="Title2" style="margin-bottom: 55px">
                    <li>利用模型获取适宜的钻井钻压/钻速/泵压等可调参数</li>
                  </div>
                  <div class="backconsult" @click="detail4" style="font-weight: bolder;color: #ed9629">
                    免费体验
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back" @click="detail5">
                <div class="imgDesign" ><img src="../../assets/alarmopen.png"></div>
                <div class="titleDesign">
                  <div class="Title1" style="margin-top: 175px">地层压力异常报警</div>
                  <div class="Title2" style="margin-bottom: 55px">
                    <li>实时监控钻井参数，通过钻井数据实时计算DC指数，精准预测地层压力，对钻井异常及时预警</li>
                  </div>
                  <div class="backconsult" @click="detail5" style="font-weight: bolder;color: #ed9629">
                    免费体验
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}"  class="card1" @click="detail6" style="width: unset">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">砂堵报警</div>
                  <div class="Title2">
                    <li>动态监测和预警砂堵风险</li>
                  </div>
                  <div class="backconsult" @click="detail6" style="font-weight: bolder;color: #ed9629">
                    免费体验
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>

            <div v-sliden-in="{duration:800}"  class="card">
              <div class="back" >
<!--              <div class="back" @click="detail2">-->
                <div class="imgDesign2"></div>
                <div class="titleDesign">
                  <div class="Title1">智能注采决策</div>
                  <div class="Title2">
                    为提高注水开发效率，施工人员需要将智能分层注水系统与智能分层采油系统相结合，通过注采联动，实时监控智能分层注水和智能分层采油情况，对各层系注水量、产液量、含水率、压力等情况进行动态对比分析，提高油藏调控的针对性与灵活性，提高注水方案执行率。
                  </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card">
              <div class="back" >
<!--              <div class="back" @click="detail3">-->
                <div class="imgDesign3"></div>
                <div class="titleDesign">
                  <div class="Title1">油气田视频监控</div>
                  <div class="Title2">
                    实现现场违章行为的实时抓取、智能识别与主动预警，还大幅节约监督人力资源，提升监管质效，有效防控安全风险，电子沙盘实施感知风险，优化了人员调配，同时客观上也提高了安全意识，规范了安全行为。
                  </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left:15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>
            </div>



            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back">
                <div class="imgDesign21"></div>
                <div class="titleDesign">
                  <div class="Title1"> 人工举升优化</div>
                  <div class="Title2">人工举升的作用是将油气开采至地面，是原油开采中的重要环节。油井在井身结构、产量、流体性质等方面差异促进了人工举升技术的多元化发展。举升智能优化合实时井口监测方案，通过减缓油田产量的下降率，可以最大程度提高油井收益。</div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back">
                <div class="imgDesign22"></div>
                <div class="titleDesign">
                  <div class="Title1">机采智能管控</div>
                  <div class="Title2">钻井地层压力异常告警系统效果卓越。它能实时监测地层压力变化，一旦发现异常，立即发
出告警，为钻井作业提供及时的安全预警。这有助于预防潜在风险，确保钻井作业的安全与
顺利进行。</div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
                </div>
              </div>


            </div>

            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back">
                <div class="imgDesign23"></div>
                <div class="titleDesign">
                  <div class="Title1">油气田能耗优化</div>
                  <div class="Title2">推进机采、注水、集输、电力四大生产系统节能降耗，推动提质增效。通过“关、停、并、转、减”，以及井筒工艺优化、机采井调平衡、低产井间开等措施，实现机采系统节电，实施电力系统配电减容、更换节能电机等措施，节约电能消耗。</div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back">
                <div class="imgDesign24"></div>
                <div class="titleDesign">
                  <div class="Title1">生产调度策略优化</div>
                  <div class="Title2">石油开采和加工的过程比较复杂，工序繁多，涉及的范围广，油田企业日常生产过程需要有计划的安排，这就需要发挥油田利用AI技术实现智能运行调度的作用，规划好油田企业的各项工作，保证企业生产和施工有序、高效的进行。</div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back">
                <div class="imgDesign25"></div>
                <div class="titleDesign">
                  <div class="Title1">设备故障主动预警</div>
                  <div class="Title2">在线监测系统可以分析设备的工作状态，预测设备的故障，并及时报警，避免设备故障导致的停工和维修时间延长。</div>
                  <div class="backconsult"  style="font-weight: bolder;color: #ed9629">
                    模型开发中...
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{ item.title1 }}</div>
                      <div class="title2" v-html="item.title2"></div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip></div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vZoomIn} from "@/utils/vZoomIn";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import {vLeftIn} from "@/utils/vLeftIn";

export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefuMobile},

  data() {

   return {
     changeVisable:1,
      title:'',
      pageTitle: '生产工艺优化GPTs_油气行业工艺类大模型人工智能应用-油气通GPT',
      pageDescription: '油气通GPT云平台提供生产工艺优化GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发。',
      pageKeywords: '工艺GPTs，生产工艺优化GPTs，智能地质导向，智能注采决策，油气田视频监控，地层压力异常工况报警平台，人工举升优化，机采智能管控，油气田能耗优化，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',

      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[
      {
          img:require("@/assets/gpt26.png"),
          title1:"XX深层煤层气钻井项目",
          title2:"安东自2022年三季度进入xx煤项目施工，2部钻机（50LDB带顶驱）参与，共施工10口深层煤层气水平井，平均井深3530m，水平段长1000-1500m，平均钻井周期30.48天（最短27.58天），多口单井或开次创区块记录，得到客户高度认可。",
        },
        {
          img:require("@/assets/gpt46.png"),
          title1:"智能采油机器人服务",
          title2:"        <li style=\"margin-top: 1vh;line-height: 20px;\">针对抽油机井，实时采集压力温度、电参、示功图、动液面等数据，输入到智能采油机器人工艺脑，输出得到最优化生产运行参数，减少无效冲次，杜绝空抽，使抽油机在最佳工况下运行，油井能够保持动液面稳定，达到了油井供采平衡，实现了油井的稳产增产。</li>\n" +
              "                        <li style=\"margin-top: 1vh;line-height: 20px;\">应用业绩：分别在大港油田、华北油田、胜利油田、中原油田、江汉油田以及延长石油开展智能采油机器人工业现场应用测试，运行稳定，取得较好的应用效果。</li>",
        },
        {
          img:require("@/assets/gpt56.png"),
          title1:"XX石油天然气脱烃增压智慧场站项目",
          title2:"<span style=\"margin-top: 0.5vh;font-weight: bold;line-height: 20px;\">设备检测，精准实时：</span><br>  根据智能化目标需求，配套高效新技术新产品，通过现场集成应用，全天候实时监测设备运行状况<br><br> <span style=\"margin-top: 0.5vh;font-weight: bold;line-height: 12px;\">方案成果：</span>  <li>降低维护成本: 及时发现潜在故障和问题，使维护更加预防性和精确，降低维护成本；</li>\n" +
              "                        <li style=\"margin-top: 0.5vh; line-height: 20px;\">增强设备可靠性: 减少设备故障和停机时间，提高设备的寿命；</li>\n" +
              "                        <li style=\"margin-top: 0.5vh;line-height: 20px;\">优化资源利用: 实时监测和数据分析帮助更好地利用资源，包括能源、原材料和人力资源，降低成本。</li>",
        },
      ]
    }

  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    this.scrollPosition = this.$refs.totalModel.scrollTop;
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {

        vm.$refs.totalModel.scrollTop = vm.scrollPosition;

      });
    });
  },
  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    detail(){
      this.$router.push("/Stratum")
    },
    detail1(){
      this.$router.push("/frack")
    },
    detail2(){
      this.$router.push("/decision1")
    },
    detail3(){
      this.$router.push("/monitior1")
    },
    detail4(){
      this.$router.push("/drilling")
    },
    detail5(){
      this.$router.push("/alarm")
    }, detail6(){
      this.$router.push("/plug")
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    // if (localStorage.getItem("skip") === '3') {
    //   this.$nextTick(() => {
    //     this.skipp3();
    //   });
    // }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '生产工艺优化GPTs_油气行业工艺类大模型人工智能应用-油气通GPT',
        pageDescription: '油气通GPT云平台提供生产工艺优化GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发。',
        pageKeywords: '工艺GPTs，生产工艺优化GPTs，智能地质导向，智能注采决策，油气田视频监控，地层压力异常工况报警平台，人工举升优化，机采智能管控，油气田能耗优化，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/gpt21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */


    .layer2 {
      position: absolute;
      top: 16.1495673671199vh;
      left: 9.17157712305026vw;

      .title1 {
        width: fit-content;
        height: 8.77626699629172vh;
        font-size: 3.50606585788562vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 4.82076637824475vh;
        width: 33.45869439630272vw;
        height: fit-content;
        font-size: 1.35979202772964vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 5.31520395550062vh;
      }
      .title4 {
        margin-top: 2.82076637824475vh;
        width: 33.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        line-height: 3.31520395550062vh;
      }
      .titlle3{
        margin-top: 5.07911001236094vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.27vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.8vh 1.5989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake0 {
    padding: 7.78739184177998vh 8.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      margin: 0 auto;
      text-align: center;
      height:8.23733003708282vh;
      opacity: 1;
      font-size: 2.2060658579vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 4.23733003708282vh;

    }

    .heightLight2 {
      margin:0 auto;
      text-align: center;
      height: 13.78739184177998vh;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

      width: 60.9693818602vw;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
    }
    .grid {
      //margin-top: 3.78739184177998vh;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;/
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            height: 69vh;
            width: 100%;
            background-image: url("../../assets/gpt22.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }



        }

      }

    }
  }
  .screenmake2{
    padding: 7.78739184177998vh 8.09763142692085vw;
    width: 100%;width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight{
      display: flex;
      justify-content: center;
      text-align: center;
      height:8.23733003708282vh;
      font-size: 2.2060658579vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.23733003708282vh;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:0.81317157712305vw ;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70.87144622991347vh ;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 36vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1.1709416522vw;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              text-align: justify;
              height: 13vh;
              font-size: 0.8243212016vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 3.194066749vh;

            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }}

      .card1{
        border-radius: 16px;
        //cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70.87144622991347vh ;
          overflow:hidden;
          background:white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt24.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 36vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1.1709416522vw;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 3.194066749vh;
            }
          }
        }
      }
      .card1:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }
      .card2{
        border-radius: 16px;
        //cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70.87144622991347vh ;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 36vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1.1709416522vw;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 3.194066749vh;
            }
          }
        }
      }
      .card2:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }
    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 8.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 2.2060658579vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      line-height: 4.23733003708282vh;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        cursor: pointer;
        width: 100%;
        height:65.87144622991347vh ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:65.87144622991347vh ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 45.60311958405546vw;
            height:100%;
            opacity: 1;
            background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
            color: white;
            width: 35%;
            float: right;
            .title1{
              margin-top: 2vh;
              font-size: 1.1709416522vw;
            }
            .title2{
              margin-top: 3vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{

                width: 100%;
                height: 8vh;
                .title3{
                  text-align: center;
                  margin-top: 2vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
}

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 550px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/gptModel21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-66px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #111111;
      line-height: 20px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:35px auto 0 auto;
        width: fit-content;
        text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width: 90%;
        text-align: center;
        height: 17px;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }

  .screenmake2{
    padding:75px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;

    }
    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt33.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign2{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt43.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign3{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: bolder;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 45px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 160px;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign21{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt44.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign22{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt45.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign23{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gptModel53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign24{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt55.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign25{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt64.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: bolder;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 52px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt34.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign11{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: bolder;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 52px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: black;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 550px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{  width: 100%;
            height:fit-content;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{

              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{ text-align: justify;
              height: 180px;
               line-height: 20px;
              font-size:14px;
              ::v-deep li{  line-height: 20px;
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 550px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -155px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
