import { render, staticRenderFns } from "./automation.vue?vue&type=template&id=74d2cf64&scoped=true"
import script from "./automation.vue?vue&type=script&lang=js"
export * from "./automation.vue?vue&type=script&lang=js"
import style0 from "./automation.vue?vue&type=style&index=0&id=74d2cf64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_nc3b4ers2qunt7cn44z4rv3gay/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d2cf64",
  null
  
)

export default component.exports