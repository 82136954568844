<template>

  <div class="totalmakeHole" ref="total">        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
    <div class="makeHole1" @click="changeheadVisable">
      <div class="background">
        <!--     文字-->
        <div class="titlle1">设备智能监测</div>
        <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div>
        <div class="title2">①&nbsp;解释类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>

        <div class="titlle3" @click="clickConsult('油气通')" style="font-weight:bolder;width:140px">
          产品咨询
        </div>


      </div>
      <div class="screenmake0">
        <div class="heighLight">运行检测分析</div>

        <div class="grid">
          <div class="card">
            <div class="back">
              <div class="imgDesign">
               <img loading="lazy" style="border-radius: 9px" src="@/assets/third15.png">

              </div>
              <div class="titleDesign">
                <div class="Title2">
                  在作业过程中实时采集设备数据，基于采样数据做到实时数据监控与分析


                </div>
              </div>


            </div>


          </div>

        </div>

      </div>
      <div class="screenmake1">
        <div class="heighLight">报警管理分析</div>

        <div class="grid">
          <div class="card">
            <div class="back">

              <div class="titleDesign">
                <div class="Title2">

                  基于大量的有标注故障分类数据，建立人工智能机器学习算法模型对设备故障实现报警提示和运维建议    </div>
              </div>
              <div class="imgDesign">
               <img loading="lazy" style="border-radius: 9px" src="@/assets/third16.png">

              </div>

            </div>


          </div>

        </div>

      </div>
      <bottomTip></bottomTip>
    </div>
    <partnerTip
        :dialogVisible="dialogconsult"
        @hideDialog="hide"
    ></partnerTip>
     <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>

</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      changeVisable:1,title:"",
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

   methods: {    topTopmobile() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },handleScroll() {
        this.scrollHeight = this.$refs.total.scrollTop;


      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 350) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '生产工艺优化GPTs_油气行业工艺类大模型人工智能应用-油气通GPT',
        pageDescription: '油气通GPT云平台提供生产工艺优化GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发。',
        pageKeywords: '工艺GPTs，生产工艺优化GPTs，智能分析地质分层，智能注采决策，油气田视频监控，地层压力异常工况报警平台，人工举升优化，机采智能管控，油气田能耗优化，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',
      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
 .topback{
    position: fixed;
    z-index:999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/third14.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3vh auto 0 auto;
        width:fit-content;
        font-size: 0.9vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:6.8430160692vh  18.9763142692085vw ;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40vh ;
            overflow:hidden;
            .imgDesign{
              height:100%;
              //width:fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                margin:15vh 4vw;
                color:#111111;
                text-align: justify;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
                width: 22vw;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake1{
      padding:6.8430160692vh  18.9763142692085vw ;
      width: 100%;
      height:  fit-content;
      background: #D4E4FF;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40vh ;
            overflow:hidden;
            .imgDesign{
              height:100%;
              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                margin:15vh 4vw;
                color:#111111;
                text-align: justify;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
                width: 22vw;
              }
              li{
                margin-top: 2vh;
              }
            }
          }
        }

      }
    }
  }

}

</style>
