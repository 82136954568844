<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="makeHole1">
          <kefu @toptop="topTop"></kefu>
          <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <div @click="changeheadVisable">
            <servicesheader :imagepath="'2'" :title1="'油气企业数据（知识）治理服务'" :title2="'安东结合数据治理服务商能力实现数据清洗、特征选择与数据标注，最终提升用户数据可用性，最大化人工智能模型效果。'" @dialogVisible="dialogVisibletrue"></servicesheader>
            <div class="screenmake3" style="background-color: white">
              <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
              <div class="grid">
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/wisdomApply3.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">
                        数据治理
                      </div>
                      <div class="title2">
                        数据治理蓝图规划，为企业设计科学的治理框架和实时路线图，聚焦主数据、元数据等管理。提升数据质量，为企业数智化转型打造坚实基础
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/wisdomApply1.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据可视化平台
                      </div>
                      <div class="title2">
                        利用BI工具和数据服务门户，构建交互式管理驾驶舱，提供多维数据分析及可视化展示，提升决策支持的实时性、准确性与业务运营透明度
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/wisdomApply2.png">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">AI智慧决策

                      </div>
                      <div class="title2">
                        利用AI技术深度挖掘数据价值，助力企业提升运营效率、降低成本、辅助决策，实现业务与管理智能化升级

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="screenmake01" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 2.2060658579vw;font-weight: bolder">方案介绍</div>
              <div v-sliden-in="{duration:800}" class="heighLight1" style=";font-weight: bolder">
                该方案可结合您企业的情况，以咨询为牵引，提供实践经验、专家资源与建议等综合服务，助力企业实现数据驱动规划与落地。提供<span style="color: #eb7c31">数据治理、数据资产管理、数据可视化平台建设、AI智慧决策等全套解决方案</span>，帮助您的企业实现成本降低、运营效率提升。

              </div>
            </div>
            <div class="screenmake21" style="padding: 6.0873918418vh 11.0976314269vw;background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 2.2060658579vw;font-weight: bolder">  业务痛点与挑战</div>
              <!--                <div class="line"></div>-->
              <div v-sliden-in="{duration:800}" class="grid">
                <!--                  <div class="card">-->
                <!--                    <div class="back">-->
                <!--                      <div class="imgDesign">-->
                <!--                        <img loading="lazy" src="@/assets/wisdompain.png" style="border-radius: 9px">-->
                <!--                      </div>-->
                <!--&lt;!&ndash;                      <div class="titleDesign">&ndash;&gt;-->
                <!--&lt;!&ndash;                        <div class="title1">数据孤岛现象&ndash;&gt;-->
                <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                        <div class="title2">&ndash;&gt;-->
                <!--&lt;!&ndash;                          企业业务系统繁多，业务数据分散，数据孤岛现象普遍，数据未较好共享应用。&ndash;&gt;-->
                <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
                <!--                    </div>-->
                <!--                  </div>-->

                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/silos.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据孤岛现象
                      </div>
                      <div class="title2">
                        企业业务系统繁多，业务数据分散，数据孤岛现象普遍，数据未较好共享应用。
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/Management.png" style="border-radius: 9px">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">企业管理问题
                      </div>
                      <div class="title2">
                        企业各领域业务数据未进行体系化管理，数据管理流程不清晰、标准不统一、制度缺失，数据安全措施不到位等。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/summarize.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据统计汇总耗费周期长
                      </div>
                      <div class="title2">
                        数据统计汇总耗费周期长，数据时效性不足，影响决策及时性。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据分析应用不充分
                      </div>
                      <div class="title2">
                        数据分析应用不充分，无法对海量业务数据进行充分分析和挖掘，数据应用场景有限，未实现数据的价值最大化。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="screenmake4" style="background-color: white">
              <div v-sliden-in="{duration:800}" class="heighLight">数据治理价值</div>
              <div class="grid">
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back" style="background-color: white;width: 90%;margin:auto">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/wisdomvalue.png">
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="screenmake0">
              <div v-sliden-in="{duration:800}" class="heighLight" style="font-weight: bolder"> 服务内容</div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <video id="myVideo" ref="myVideo" width="100%" height="100%" style="border-radius: 9px" controls>
                        <source src="@/video/wisdom.mp4" type="video/mp4">
                      </video>
                    </div>
                    <div class="titleDesign">
                      <div class="title2">
                        <li>智慧决策平台建设需求调研</li>
                        <li>数据可视化架构设计</li>
                        <li>数据中台及架构规划</li>
                        <li>模型规则设计</li>
                        <li>数据治理及体系完善</li>
                        <li>数据驱动项目管理及实施</li>
                        <li>各类角色用户使用推广培训</li>
                        <li>智慧决策平台运行维护、优化</li>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="screenmake1">
              <div v-sliden-in="{duration:800}" class="heighLight" style="font-weight: bolder">成功案例</div>
              <div class="grid">
                <el-carousel indicator-position="outside" arrow="always" interval="5000">
                  <el-carousel-item v-for="item in success" :key="item">
                    <div class="card">
                      <div class="back">
                        <div v-left-in="{duration:800}" class="imgDesign">
                          <img loading="lazy" :src="item.img"/>
                        </div>
                        <div v-sliden-in="{duration:800}" class="titleDesign">
                          <div class="title1">{{item.title1}}</div>
                          <div class="title2" v-html="item.title2"></div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
            <bottomTip></bottomTip></div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel"  ref="totalModel">
        <div class="makeHole1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
<!--          <headtip @showDialog="showPartner"></headtip>-->
<!--          <div class="background">-->
<!--            &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->
<!--            &lt;!&ndash;     文字&ndash;&gt;-->
<!--            <div class="layer2">-->
<!--              <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 34px;color:#F44336">数据驱动智慧决策平台设计<br>及建设服务</div>-->
<!--              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 40px;color:#2c3e50;text-align: left">-->
<!--                <li v-sliden-instep="{duration:1000}">统一的数据湖管理平台，链接您的业务系统</li>-->
<!--                <li v-sliden-instep="{duration:1200}">生产运营数据可视化</li>-->
<!--                <li v-sliden-instep="{duration:1400}">AI驱动实时业务数据查询</li>-->
<!--                <li v-sliden-instep="{duration:1600}">经营管理风险智能预警、辅助决策</li>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="title3" @click="clickConsult('油气通')" style="font-weight: bolder;width: 120px">-->
<!--              产品咨询-->
<!--            </div>-->
<!--          </div>-->
          <service-header-mobile :imagepath="'/constructBanner.png'" :title1="'油气企业数据（知识）治理服务'" :title2="'安东结合数据治理服务商能力实现数据清洗、特征选择与数据标注，最终提升用户数据可用性，最大化人工智能模型效果。'" @clickbtn1="showPartner" @clickbtn2="clickConsult('油气通')"></service-header-mobile>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: 1000px">
              <image-up-text-down-mobile v-sliden-in="{duration:800+400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1" :image-design_-height="'100%'" :image-design_-width="'100%'"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>

          </div>
          <div class="screenmake21" >
            <div v-sliden-in="{duration:800}" class="heighLight" >  业务痛点与挑战</div>
            <div class="line"></div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/silos.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据孤岛现象
                    </div>
                    <div class="title2">
                      企业业务系统繁多，业务数据分散，数据孤岛现象普遍，数据未较好共享应用。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Management.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">企业管理问题
                    </div>
                    <div class="title2">
                      企业各领域业务数据未进行体系化管理，数据管理流程不清晰、标准不统一、制度缺失，数据安全措施不到位等。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/summarize.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据统计汇总耗费周期长
                    </div>
                    <div class="title2">
                      数据统计汇总耗费周期长，数据时效性不足，影响决策及时性。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据分析应用不充分
                    </div>
                    <div class="title2">
                      数据分析应用不充分，无法对海量业务数据进行充分分析和挖掘，数据应用场景有限，未实现数据的价值最大化。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake111" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: 850px">
              <image-up-text-down-mobile v-sliden-in="{duration:800}" v-for="elemnt in zhili" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>
          </div>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight"> 服务内容</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <!--                    <img loading="lazy" style="border-radius: 9px" src="../../assets/wisdomnew.png">-->
                    <video id="myVideo" ref="myVideo" width="100%" height="100%" style="border-radius: 9px" controls>
                      <source src="@/video/wisdom.mp4" type="video/mp4">
                    </video>
                  </div>

                  <div class="titleDesign">
                    <div class="title2">
                      <li>智慧决策平台建设需求调研</li>
                      <li>数据可视化架构设计</li>
                      <li>数据中台及架构规划</li>
                      <li>模型规则设计</li>
                      <li>数据治理及体系完善</li>
                      <li>数据驱动项目管理及实施</li>
                      <li>各类角色用户使用推广培训</li>
                      <li>智慧决策平台运行维护、优化</li>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy"  :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vLeftIn} from "@/utils/vLeftIn";
import ImageUpTextDownMobile from "@/views/components/imageUpTextDownMobile.vue";
import servicesheader from "@/views/services/components/servicesheader.vue";
import ServiceHeaderMobile from "@/views/services/components/serviceHeaderMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {
    ServiceHeaderMobile,
    servicesheader, ImageUpTextDownMobile, PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
      yourBenfits:[
        {
          imagepath:'/wisdomApply3.png',
          title1:'数据治理',
          title2:'数据治理蓝图规划，为企业设计科学的治理框架和实时路线图，聚焦主数据、元数据等管理。提升数据质量，为企业数智化转型打造坚实基础'
        },
        {
          imagepath:'/wisdomApply1.png',
          title1:'数据可视化平台',
          title2:'利用BI工具和数据服务门户，构建交互式管理驾驶舱，提供多维数据分析及可视化展示，提升决策支持的实时性、准确性与业务运营透明度'
        },
        {
          imagepath:'/wisdomApply2.png',
          title1:'AI智慧决策',
          title2:'利用AI技术深度挖掘数据价值，助力企业提升运营效率、降低成本、辅助决策，实现业务与管理智能化升级'
        }
      ],
      zhili:[
        {
          imagepath:'/wisdomZhili1.png',
          title1:'油气勘探与开发',
          title2:'<li>大数据预测油气藏位置和储量</li><li>分析、构建地质模型</li>'
        },
        {
          imagepath:'/wisdomZhili2.png',
          title1:'油田生产与优化',
          title2:'<li>实时生产数据监控</li>' +
              '<li>分析、预测设备故障</li>' +
              '<li>自动工艺参数优化\n</li>'
        },
        {
          imagepath:'/wisdomZhili3.png',
          title1:'企业管理与优化',
          title2:'<li>数字化转型与运营效率提升</li>' +
              '<li>优化预算分配和成本控制\n</li>'
        },{
          imagepath:'/wisdomZhili4.png',
          title1:'环境保护与安全管理',
          title2:'<li>环境监测与合规管理</li>' +
              '<li>识别潜在安全风险并预警</li>' +
              '<li>分析预测和防止安全事故</li>'
        },
        {
          imagepath:'/wisdomZhili5.png',
          title1:'供应链管理与物流',
          title2:'<li>智能物流运输、储存和分配</li>' +
              '<li>库存成本、积压降低</li>'
        },
        {
          imagepath:'/wisdomZhili6.png',
          title1:'能源转型与可持续发展',
          title2:'<li>碳排放精准监测、计算</li>' +
              '<li>可再生能源资源评估</li>'
        },
        {
          imagepath:'/wisdomZhili7.png',
          title1:'市场分析与商业决策',
          title2:'<li>需求预测与市场分析</li>' +
              '<li>数据可视与科学决策</li>'
        }
      ],
      changeVisable:1,
      title:'',
      pageTitle: '数据驱动智慧决策平台设计及建设服务_企业管理GPT-油气通GPT',
      pageDescription: '油气通GPT云平台（www.oilgasgpts.com）提供数据驱动智慧决策平台设计及建设服务，以数据驱动和智慧决策，构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。',
      pageKeywords: '数据驱动，智慧决策平台，数据决策平台，数据决策平台建设，企业管理GPT，油气通，油气通GPT，油气通GPT云平台',
      model:false,
      scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        // img:require("@/assets/wisdom.png"),
        img:require("@/assets/wisdomsuccess1.png"),
        title1:"数据驱动智慧决策平台",
        title2:"数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。",
      },{
        img:require("@/assets/wisdom2.png"),
        title1:"数据驱动智慧决策平台",
        title2:"数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。",
      },
        {
          img:require("@/assets/wisdomsuccess2.png"),
          title1:"数据驱动智慧决策平台",
          title2:"数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。",
        },
      ]
    }

  },

  methods: {     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    console.log("defefr")

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {



    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '数据驱动智慧决策平台设计及建设服务_企业管理GPT-油气通GPT',
        pageDescription: '油气通GPT云平台（www.oilgasgpts.com）提供数据驱动智慧决策平台设计及建设服务，以数据驱动和智慧决策，构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。',
        pageKeywords: '数据驱动，智慧决策平台，数据决策平台，数据决策平台建设，企业管理GPT，油气通，油气通GPT，油气通GPT云平台',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/wisdomBanner.png");

      background-position: right center; /* 靠右对齐 */
      background-size: auto 100%; /* 左侧填充 */
      //background-position: center;
      //background-size:cover;
      background-repeat: no-repeat;
      background-color: #a4b1e6;

      .layer2 {
        margin-top: 4vh;
        margin-left:2vw;

        .title1 {
          width: fit-content;
          height: 7.77626699629172vh;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 3vh;
          width: 35vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.3vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }
        .title4 {
          margin-top: 1vh;
          width: 35vw;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          line-height:5vh;
        }
        .titlle3{
          position: absolute;
          bottom: 7.78739184177998vh;
          margin-top: 4.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;

          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9.1px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;


        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 58.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake01{
      padding:7.8430160692vh  9vw 0 9vw ;
      width: 100%;
      height:  fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 78vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 4.5vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw ;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            display: flex;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40vh ;
            padding: 0 0 3vh 0;
            overflow:hidden;
            .imgDesign{
              height:40vh;
              width: 500vh;
              margin:0vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              margin:0 1vw ;
              .Title1{
                width: 30vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              .Title2{
                // display: flex;
                // flex-direction: column;
                // justify-content: center; /* Center vertically */
                // align-items: flex-start;
                // color:#111111;
                // text-align: justify;
                // font-size: 0.9vw;
                // height: fit-content;
                // font-family: Sarial, sans-serif, "Microsoft Yahei";
                // font-weight: 400;
                // line-height: 4vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .line{
        height: 9vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr)  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) ;
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:20vh;
        z-index: 1;

      }
      .grid{
        z-index: 2;
        margin-top:0vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 4vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            //border: white 1px solid;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100%;
            overflow:hidden;
            .imgDesign{
              width:100%;
              height:fit-content;
              background-color: white;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:2vh auto 0 auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin: 2vh 2vw;
                width: fit-content;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      padding: 5.78739184177998vh 8.09763142692085vw 0vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:20vh;
        z-index: 1;

      }
      .grid{
        z-index: 2;
        margin-top:0vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) ;
        column-gap: 4vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            //border: white 1px solid;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100%;
            overflow:hidden;
            .imgDesign{
              width:100%;
              height:fit-content;
              background-color: white;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:2vh auto 0 auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin: 2vh 2vw;
                width: fit-content;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:45vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 0;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 40%;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top:10px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}


.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 500px;
      background-image: url("../../assets/wisdomBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        // position: absolute;
        // left:0;
        margin-top:20px;
        .title1 {
          margin:0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 23px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #111111;

          line-height:32px;
        }

        .title2 {
          margin: 20px auto 0 auto;
          width: 90%;
          text-align: left;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #111111;
          line-height: 24px;
        }

        .title3 {
          margin: 15px auto;
          width: fit-content;
          text-align: left;
          height: fit-content;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #111111;
          line-height: 24px;
        }
      }
      .title3 {
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }
    .screenmake111 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            background: transparent;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;

        .card {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height: 185px;
          background-color: #ebf1fd;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 5px 10px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 50px;
              height: 45px;
              margin: 0 auto;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            background: transparent;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;

        .card {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height: 185px;
          background-color: #ebf1fd;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 5px 10px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 45px;
              margin: 0 auto;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      padding:15px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    //.screenmake0{
    //  //z-index:: 10;
    //
    //  //left: 10.86077411900635vw;
    //  width: 100vw;
    //  height: fit-content;
    //  padding:20px 4.6875vw;
    //  background: white;
    //  .heighLight{
    //    text-align: center;
    //    height:21px;
    //    opacity: 1;
    //    font-size: 21px;
    //    //color: #53A3F7;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 700;
    //    line-height: 26px;
    //  }
    //  .heightLight2{
    //    margin:19px 2vw;
    //    text-align: justify;
    //    width: fit-content;
    //    height: fit-content;
    //    font-size:14px;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    color: #2C3E50;
    //    line-height: 20px;
    //  }
    //
    //  .grid{
    //    position: relative;
    //    height: fit-content;
    //    .card{
    //      cursor: pointer;
    //      width: 100%;
    //      height:fit-content ;
    //      padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
    //      opacity: 1;
    //      .title1{
    //        margin-top: 5px;
    //        font-size: 16px;
    //        font-weight: bold;
    //      }
    //      .title2{
    //        margin-top: 5px;
    //
    //        line-height: 22px;
    //        font-size: 14px;
    //      }
    //      .title3{
    //        margin-top: 5px;
    //        display: grid;
    //        grid-template-columns: minmax(0,1fr) ;
    //        line-height: 22px;
    //        font-size: 14px;
    //      }
    //      .titleDesign{
    //        margin-top: 10px;
    //      }
    //      .back{
    //        background: white;
    //        border-radius: 9px;
    //        display: grid;
    //        grid-template-columns: minmax(0,1fr);
    //        column-gap: 5px;
    //        position: relative;
    //        height:fit-content ;
    //        margin-top: 15px;
    //        overflow:hidden;
    //        .imgDesign{
    //          width: 100%;
    //          //border: #666666 1px solid;
    //          height:fit-content;
    //          opacity: 1;
    //          //background-image: url("../../assets/menage6.png");
    //          background-position: center;
    //          background-size:cover;
    //          background-repeat: no-repeat;
    //          background-color: transparent;
    //        }
    //
    //
    //      }
    //
    //    }
    //
    //    ::v-deep .el-carousel__container{
    //      height: 65.87268232385661vh !important;
    //    }
    //    .el-carousel__item h3 {
    //      color: white;
    //      opacity: 0.75;
    //      margin: 0;
    //    }
    //
    //    .el-carousel__item:nth-child(2n) {
    //      background-color: transparent;
    //    }
    //
    //    .el-carousel__item:nth-child(2n+1) {
    //      background-color: transparent;
    //    }
    //    ::v-deep .el-carousel__container {
    //      position: relative;
    //      height: 48.83559950556242vh;
    //    }
    //    ::v-deep .el-carousel__arrow {
    //      display: none;
    //      border: none;
    //      outline: 0;
    //      padding: 0;
    //      margin: 0;
    //      cursor: pointer;
    //      transition: 0.3s;
    //      border-radius: 0;
    //      width: 1.15540150202195vw;
    //      height: 4.32632880098888vh;
    //      //background: #2168DB;
    //      color: #fff;
    //      position: absolute;
    //      top: 50%;
    //      //z-index:: 10;
    //      transform: translateY(-50%);
    //      text-align: center;
    //      font-size: 12px;
    //    }
    //  }
    //}

    .screenmake1{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 390px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -85px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
