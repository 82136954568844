<script >
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vLeftIn} from "@/utils/vLeftIn";
export default {
  // props:['title1' ]
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  props: {
    title1: {
      type: String,
      default: '解决方案' // 设置默认值
    },
    title2:{
      type:String,
      default: '*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。'
    },
    imagepath:{
      type:String,
      // default:"@/src/assets/ground1a.png"
      default: '1'
      // default: '../../../assets/ground1a.png'

    }
  },
  data(){
    return{
      image:"",
      imgurl:""
    }
  },
  methods:{
    showDialog() {
      this.$emit('dialogVisible', true); // 传递 true 表示 dialogVisible 打开
    }
  },
  // created() {
  //   this.image=require(this.imagepath)
  // },
  computed:{
    imgurlpath(){
      return require(`${this.imagepath}`)
    },
    backgroundStyle(){
      let imageUrl=""

      switch (this.imagepath){
        case '1':
          imageUrl = require(`../../../assets/gpt11.jpg`);
          break;
        case '2':
          imageUrl = require(`../../../assets/menage1.png`);
          break;
        case '3':
          imageUrl = require(`../../../assets/gpt21.png`);
          break;
        case '4':
          imageUrl = require(`../../../assets/oil1.png`);
          break;
        case '5':
          imageUrl = require(`../../../assets/gpt71.png`);
          break;
        case '6':
          imageUrl = require(`../../../assets/equ1a.png`);
          break;
      }

      // if(this.imagepath=="1"){
      //   imageUrl = require(`../../../assets/ground1a.png`);
      // }

      // let imageUrl = require('../../../assets/ground1a.png')
      return{
        // this.imgurl=
        // backgroundImage:`url(${this.imagepath})`

        backgroundImage:`url(${imageUrl})`
        // backgroundImage:`url(${imageUrl}), linear-gradient(to bottom, #d5dcef 0%, #dfe6f3 60.1%, #cad6ed 40%, #cfd9ee 100%)`
        // backgroundImage: `url('@/src/assets/ground1a.png'), linear-gradient(to bottom, #d5dcef 0%, #dfe6f3 60.1%, #cad6ed 40%, #cfd9ee 100%)`
      }
    }
  }
}
</script>

<template>
  <div class="background" :style="backgroundStyle" >
<!--  <div class="background" :style="{backgroundImage:  `url(${this.imagepath})`}">-->
    <!--     文字-->
    <div class="layer2">


    <div v-sliden-in="{duration:800}" class="title1" style="font-weight: bolder;color:black " >
      {{title1}}
    </div>
    <div v-sliden-instep="{duration:1200}" class="title4" style="font-weight: bolder;margin-top: 6vh;color:black;line-height: 7vh">
      {{title2}}
    </div>
    <div class="title3" @click="showDialog" >
      解决方案咨询
    </div>
    </div>
  </div>
</template>

<style scoped>

.background{
  position: relative;
    padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    width: 100%;
    height: 72.41285537700865vh;
    //height: 72.41285537700865vh;
    //background-image: url('../../../assets/ground1a.png'),linear-gradient(to bottom, #d5dcef 0%, #dfe6f3 60.1%, #cad6ed 40%, #cfd9ee 100%);
   //background-color: blue;
    background-position: right center; /* 靠右对齐 */
    background-size: auto 100%; /* 左侧填充 */
    //background-position: center;
    //background-size:cover;
    background-repeat: no-repeat;
    background-color: #cbd6ed;
    .layer2 {
      margin-top: 4vh;
      margin-left:5vw;
      .title1 {
        width: fit-content;
        height: 7.77626699629172vh;
        font-size: 2.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }
      .title4 {
        margin-top: 1vh;
        width: 35vw;
        font-size: 1.3vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        line-height:5vh;
      }
      .title3{
        position: absolute;
        bottom: 7.78739184177998vh;
        margin-top: 4.07911001236094vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bolder;
        color: #ffffff;

        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;


      }
      .title3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    /* Add any additional styling as needed */

  }


</style>
